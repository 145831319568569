// Remove or comment out this line
//import logo from './logo.svg';
import './App.css';
import SpeechToTextComponent from './SpeechToTextComponent';


function App() {
  return (
    <div className="App">
      <SpeechToTextComponent />
    </div>
  );
}

export default App;