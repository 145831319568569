import React, { useState, useEffect, useRef, useCallback } from 'react';
import colorLogo from './assets/color.png';
import * as sdk from 'microsoft-cognitiveservices-speech-sdk';
import * as microsoftTeams from "@microsoft/teams-js";

const SPEECH_KEY = '9qBhM45nuEmfzTDDTyJFSl0j7e8f317z9z3BUxTrbhD1qL4H8dFMJQQJ99AJAC5RqLJXJ3w3AAAYACOGfJHi';
const SPEECH_REGION = 'westeurope';
const TRANSLATOR_KEY = 'AAzgUJTaz9kanUktd6RBCeLugLFAwcV450bH2Ho2xsq4potwPzhaJQQJ99AJAC5RqLJXJ3w3AAAbACOG4sRG';
const TRANSLATOR_REGION = 'westeurope';
const API_BASE_URL = 'https://cscvoiceapi.azurewebsites.net';
const DISPLAY_DURATION = 5000; // Her konuşmanın ekranda kalma süresi (ms)

//const DEFAULT_MEETING_ID = 'default-meeting';
const DEFAULT_USER_NAME = 'Misafir Kullanıcı';

const SUPPORTED_LANGUAGES = [
  { 
    code: 'tr-TR', 
    name: 'Türkçe', 
    shortCode: 'tr',
    icon: '🇹🇷',
    phraseList: ['merhaba', 'günaydın', 'iyi akşamlar']
  },
  { 
    code: 'en-US', 
    name: 'İngilizce', 
    shortCode: 'en',
    icon: '🇺🇸',
    phraseList: ['hello', 'good morning', 'good evening']
  },
  { 
    code: 'ru-RU', 
    name: 'Rusça', 
    shortCode: 'ru',
    icon: '🇷🇺',
    phraseList: ['привет', 'доброе утро', 'добрый вечер']
  }
];

// API çağrı yardımcı fonksiyonu
const apiCall = async (endpoint, options = {}) => {
  try {
    console.log(`API çağrısı yapılıyor: ${endpoint}`, options);

    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
      ...options,
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json; charset=UTF-8',
        'Accept-Charset': 'UTF-8',
        ...options.headers
      }
    });

    const data = await response.json();
    
    if (!response.ok) {
      throw new Error(data.error || `HTTP error! status: ${response.status}`);
    }

    if (data.error) {
      throw new Error(data.error);
    }

    console.log(`API yanıtı alındı: ${endpoint}`, data);
    return data;
  } catch (error) {
    console.error(`API Hatası (${endpoint}):`, error);
    throw error;
  }
};
export function SpeechToTextComponent() {
  // State tanımlamaları
  const [isListening, setIsListening] = useState(false);
  const [error, setError] = useState('');
  const [micPermission, setMicPermission] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('tr-TR');
  const [userName, setUserName] = useState(DEFAULT_USER_NAME);
  const [userInfo, setUserInfo] = useState(null);
  const [meetingInfo, setMeetingInfo] = useState(null);
  const [isMeetingActive, setIsMeetingActive] = useState(false);
  const [isTeamsClient, setIsTeamsClient] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);
  const [speechServiceReady, setSpeechServiceReady] = useState(false);
  const [displayedMessages, setDisplayedMessages] = useState([]);
  const [lastFetchedMessageId, setLastFetchedMessageId] = useState(0);

  // Refs
  const recognizers = useRef({});
  const isProcessing = useRef(false);
  const isTeamsInitialized = useRef(false);
  const isTeamsMeetingInitialized = useRef(false);
  const messageQueueRef = useRef([]);
  const displayTimeoutRef = useRef(null);
  const currentMeetingDataRef = useRef(null);
  const isMeetingActiveRef = useRef(false);

  // Teams kontrolü
  const checkTeamsContext = useCallback(async () => {
    try {
      console.log('Teams context kontrolü başlatılıyor...');
      await microsoftTeams.app.initialize();
      const context = await microsoftTeams.app.getContext();
      
      if (context) {
        console.log('Teams context bulundu:', context);
        setIsTeamsClient(true);
        return true;
      }
      return false;
    } catch (err) {
      console.log('Teams context bulunamadı:', err);
      setIsTeamsClient(false);
      return false;
    }
  }, []);

  // Speech servis kontrolü
  const checkSpeechService = useCallback(async () => {
    try {
      console.log('Speech servis kontrolü başlatılıyor...');
      const speechConfig = sdk.SpeechConfig.fromSubscription(SPEECH_KEY, SPEECH_REGION);
      if (speechConfig) {
        console.log('Speech servis hazır');
        setSpeechServiceReady(true);
        return true;
      }
      return false;
    } catch (err) {
      console.error('Speech servis hatası:', err);
      setSpeechServiceReady(false);
      return false;
    }
  }, []);

  // Çeviri fonksiyonu
  const translateText = useCallback(async (text, fromLang, toLang) => {
    if (!text?.trim()) return '';
    try {
      console.log('Çeviri başlatılıyor:', { text, fromLang, toLang });
      const response = await fetch(
        `https://${TRANSLATOR_REGION}.api.cognitive.microsoft.com/translator/text/v3.0/translate?api-version=3.0&from=${fromLang}&to=${toLang}`,
        {
          method: 'POST',
          headers: {
            'Ocp-Apim-Subscription-Key': TRANSLATOR_KEY,
            'Content-Type': 'application/json',
            'Ocp-Apim-Subscription-Region': TRANSLATOR_REGION
          },
          body: JSON.stringify([{ Text: text }])
        }
      );

      const data = await response.json();
      console.log('Çeviri tamamlandı:', data);
      return data[0]?.translations[0]?.text || 'Çeviri başarısız';
    } catch (error) {
      console.warn('Çeviri hatası:', error);
      return 'Çeviri başarısız';
    }
  }, []);

  // Dil değişikliği handler'ı
  const handleLanguageChange = async (e) => {
    if (isListening) return;
    
    const newLanguage = e.target.value;
    console.log('Dil değiştiriliyor:', { newLanguage, meetingInfo, userInfo });
  
    try {
      if (meetingInfo?.id && userInfo?.id) {
        // Kullanıcı tercihini güncelle
        await apiCall('/api/user-preferences', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            meetingId: meetingInfo.id,
            userId: userInfo.id,
            languageId: newLanguage
          })
        });
  
        // State'i güncelle
        setSelectedLanguage(newLanguage);
        
        // Mesaj listesini yenile
        setLastFetchedMessageId(0);
        setDisplayedMessages([]);
        messageQueueRef.current = [];
        await fetchNewMessages();
  
        console.log('Dil tercihi güncellendi:', newLanguage);
      } else {
        console.error('Meeting veya user bilgisi eksik:', { meetingInfo, userInfo });
      }
    } catch (error) {
      console.error('Dil değiştirme hatası:', error);
      setError('Dil tercihi kaydedilemedi: ' + error.message);
    }
  };

  // Mikrofon kontrolü
  const checkMicrophonePermission = useCallback(async () => {
    try {
      console.log('Mikrofon izni kontrol ediliyor...');
      const stream = await navigator.mediaDevices.getUserMedia({ 
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true
        } 
      });
      stream.getTracks().forEach(track => track.stop());
      console.log('Mikrofon izni alındı');
      setMicPermission(true);
      setError('');
      return true;
    } catch (err) {
      console.error('Mikrofon izni hatası:', err);
      setError('Mikrofon erişimi reddedildi. Lütfen izin verin.');
      setMicPermission(false);
      return false;
    }
  }, []);
  // Yeni mesajları getirme fonksiyonu
  const fetchNewMessages = useCallback(async () => {
    if (!meetingInfo?.id || !userInfo?.id) {
      console.log('Meeting veya user bilgisi eksik:', { meetingInfo, userInfo });
      return;
    }

    try {
      console.log('Fetching messages:', {
        meetingId: meetingInfo.id,
        userId: userInfo.id,
        lastMessageId: lastFetchedMessageId
      });

      const response = await apiCall(`/api/messages?meetingId=${meetingInfo.id}&userId=${userInfo.id}&lastMessageId=${lastFetchedMessageId}`);
      
      console.log('Fetched messages:', response);

      if (response.success && response.messages?.length > 0) {
        messageQueueRef.current.push(...response.messages);
        setLastFetchedMessageId(response.metadata.lastMessageId);
      }
    } catch (error) {
      console.error('Message fetch error:', error);
    }
  }, [meetingInfo?.id, userInfo?.id, lastFetchedMessageId]);

  // Mesajları gösterme fonksiyonu
  const displayNextMessage = useCallback(() => {
    if (messageQueueRef.current.length === 0) {
      fetchNewMessages();
      return;
    }

    const message = messageQueueRef.current.shift();
    if (message) {
      console.log('Displaying message:', message);
      setDisplayedMessages(prev => {
        // Mesaj zaten gösteriliyorsa ekleme
        if (prev.some(m => m.id === message.id)) {
          return prev;
        }
        // Son 3 mesajı göster
        return [...prev, message].slice(-3);
      });
    }

    displayTimeoutRef.current = setTimeout(() => {
      displayNextMessage();
    }, DISPLAY_DURATION);
  }, [fetchNewMessages]);

  // Speech Recognition temizleme
  const cleanupRecognizers = useCallback(() => {
    console.log('Recognizer\'lar temizleniyor...');
    Object.values(recognizers.current).forEach(recognizer => {
      if (recognizer) {
        try {
          recognizer.stopContinuousRecognitionAsync(
            () => {
              recognizer.close();
              console.log('Recognizer başarıyla temizlendi');
            },
            (err) => console.warn('Recognizer temizleme uyarısı:', err)
          );
        } catch (err) {
          console.warn('Recognizer temizleme hatası:', err);
        }
      }
    });
    recognizers.current = {};
  }, []);

  // Dinlemeyi durdur
  const stopListening = useCallback(async () => {
    try {
      if (!isListening) return;
      console.log('Dinleme durduruluyor...');
  
      const stopPromises = Object.values(recognizers.current).map(recognizer => {
        return new Promise((resolve) => {
          if (!recognizer) {
            resolve();
            return;
          }
          recognizer.stopContinuousRecognitionAsync(
            () => {
              console.log('Recognizer durduruldu');
              resolve();
            },
            (error) => {
              console.warn('Recognizer durdurma uyarısı:', error);
              resolve();
            }
          );
        });
      });
  
      await Promise.all(stopPromises);
      setIsListening(false);
  
      // Meeting'i sonlandır
      if (currentMeetingDataRef.current?.id) {
        try {
          await apiCall('/api/meetings', {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              meetingId: currentMeetingDataRef.current.id,
              status: 'completed',
              endDate: new Date().toISOString().slice(0, 19).replace('T', ' ')
            })
          });
  
          console.log('Meeting başarıyla sonlandırıldı');
        } catch (error) {
          console.warn('Meeting sonlandırma hatası:', error);
        }
      }
  
      // Ref'leri ve state'leri temizle
      currentMeetingDataRef.current = null;
      isMeetingActiveRef.current = false;
      isTeamsMeetingInitialized.current = false;
      setMeetingInfo(null);
      setIsMeetingActive(false);
      
    } catch (err) {
      console.error('Dinlemeyi durdurma hatası:', err);
      setIsListening(false);
    }
  }, [isListening]);

  // Konuşma işleme
  const processRecognitionResult = useCallback(async (lang, transcript, confidence) => {
    if (!transcript?.trim() || isProcessing.current) {
      console.log('İşlem atlandı - boş metin veya işlem devam ediyor');
      return;
    }
  
    // Meeting durumunu ref'ten kontrol et
    if (!isMeetingActiveRef.current) {
      console.log('Meeting aktif değil, işlem atlanıyor');
      return;
    }
  
    const currentMeeting = currentMeetingDataRef.current;
    if (!currentMeeting?.id) {
      console.error('Meeting bilgisi eksik');
      return;
    }
  
    isProcessing.current = true;
  
    try {
      const cleanText = transcript.trim();
      console.log('Konuşma kaydediliyor:', {
        text: cleanText,
        meetingId: currentMeeting.id,
        userId: userInfo.id,
        userName: userInfo.displayName,
        lang: lang.code,
        confidence
      });
  
      // Speech kayıt isteği
      const speechData = {
        meetingId: currentMeeting.id,
        userId: userInfo.id,
        userName: userInfo.displayName || userInfo.userPrincipalName || 'Unknown User',
        sourceText: cleanText,
        confidence: parseFloat(confidence),
        sourceLanguageId: lang.code,
        sourceWordCount: cleanText.split(/\s+/).length
      };
  
      const speechResponse = await apiCall('/api/speech-records', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf8mb4'
        },
        body: JSON.stringify(speechData)
      });
  
      console.log('Speech kayıt yanıtı:', speechResponse);
  
      if (speechResponse.success && speechResponse.recordId) {
        // Çevirileri yap
        await Promise.all(SUPPORTED_LANGUAGES
          .filter(targetLang => targetLang.code !== lang.code)
          .map(async targetLang => {
            const translation = await translateText(
              cleanText,
              lang.shortCode,
              targetLang.shortCode
            );
  
            if (translation && translation !== 'Çeviri başarısız') {
              return apiCall('/api/translations', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json; charset=utf8mb4'
                },
                body: JSON.stringify({
                  recordId: speechResponse.recordId,
                  languageId: targetLang.code,
                  translatedText: translation,
                  meetingId: currentMeeting.id,
                  wordCount: translation.split(/\s+/).length
                })
              });
            }
          }));
      }
    } catch (error) {
      console.error('İşlem hatası:', error);
      setError('Konuşma kaydedilemedi: ' + error.message);
    } finally {
      isProcessing.current = false;
    }
  }, [userInfo, translateText]);

  // Speech Recognition başlatma
  const initializeSpeechRecognition = useCallback(() => {
    return new Promise((resolve, reject) => {
      try {
        console.log('Speech recognition başlatılıyor...', {
          meetingInfo: currentMeetingDataRef.current,
          isActive: isMeetingActive,
          isInitialized: isTeamsMeetingInitialized.current
        });
  
        if (!currentMeetingDataRef.current?.id) {
          throw new Error('Meeting bilgisi eksik');
        }
  
        cleanupRecognizers();
  
        const selectedLang = SUPPORTED_LANGUAGES.find(lang => lang.code === selectedLanguage);
        if (!selectedLang) throw new Error(`Desteklenmeyen dil: ${selectedLanguage}`);
  
        const speechConfig = sdk.SpeechConfig.fromSubscription(SPEECH_KEY, SPEECH_REGION);
        speechConfig.speechRecognitionLanguage = selectedLang.code;
  
        // Sessizlik ayarları
        speechConfig.setProperty(
          sdk.PropertyId.SpeechServiceConnection_InitialSilenceTimeoutMs,
          "15000"
        );
        
        speechConfig.setProperty(
          sdk.PropertyId.SpeechServiceConnection_EndSilenceTimeoutMs,
          "5000"
        );
  
        const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
        const recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);
  
        // Event handler'ları
        recognizer.recognized = async (s, e) => {
          if (e.result.reason === sdk.ResultReason.RecognizedSpeech) {
            const transcript = e.result.text?.trim();
            const confidence = e.result.confidence || 0.5;
            
            // Her tanıma işleminde meeting durumunu kontrol et
            if (!isMeetingActive) {
              console.log('Meeting aktif olmadığı için dinleme durduruluyor');
              stopListening();
              return;
            }
        
            if (transcript && confidence > 0.1) {
              console.log('Konuşma tanındı:', { transcript, confidence });
              await processRecognitionResult(selectedLang, transcript, confidence);
            }
          }
        };
  
        recognizer.recognizing = (s, e) => {
          if (e.result.text) {
            console.log('Tanıma devam ediyor:', e.result.text);
          }
        };
  
        // PhraseList ekle
        if (selectedLang.phraseList?.length > 0) {
          const phraseList = sdk.PhraseListGrammar.fromRecognizer(recognizer);
          selectedLang.phraseList.forEach(phrase => phraseList.addPhrase(phrase));
        }
  
        recognizers.current[selectedLang.code] = recognizer;
        console.log('Speech recognition başarıyla başlatıldı');
        resolve();
  
      } catch (err) {
        console.error('Speech recognition başlatma hatası:', err);
        setError('Speech recognition başlatılamadı: ' + err.message);
        reject(err);
      }
    });
  }, [selectedLanguage, processRecognitionResult, cleanupRecognizers]);
  
  // Dinlemeyi başlat
  const startListening = useCallback(async () => {
    try {
      if (isListening) return;
      console.log('Dinleme başlatılıyor...', { userInfo });
      
      if (!isTeamsClient) {
        throw new Error('Teams dışında çalıştırılamaz');
      }
  
      if (!speechServiceReady) {
        throw new Error('Speech servisi hazır değil');
      }
  
      const hasMicPermission = await checkMicrophonePermission();
      if (!hasMicPermission) {
        throw new Error('Mikrofon izni yok');
      }
  
      await stopListening();
  
      // Meeting başlatma
      const context = await microsoftTeams.app.getContext();
      if (!context?.meeting?.id) {
        throw new Error('Meeting ID bulunamadı');
      }
  
      const meetingData = {
        id: context.meeting.id,
        name: context.meeting.name || 'Teams Meeting'
      };
  
      // Meeting'i aktifleştir
      const response = await apiCall('/api/meetings', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          meetingId: meetingData.id,
          meetingName: meetingData.name,
          hostUserId: userInfo?.id,
          hostUserName: userInfo?.displayName,
          status: 'active'
        })
      });
  
      if (!response.success) {
        throw new Error('Meeting başlatılamadı');
      }
  
      // Ref'leri güncelle
      currentMeetingDataRef.current = meetingData;
      isMeetingActiveRef.current = true;
      isTeamsMeetingInitialized.current = true;
  
      // State'leri güncelle
      setMeetingInfo(meetingData);
      setIsMeetingActive(true);
  
      // State güncellemelerinin tamamlanmasını bekle
      await new Promise(resolve => setTimeout(resolve, 100));
  
      // Kullanıcı dil tercihini kaydet
      await apiCall('/api/user-preferences', {
        method: 'POST',
        body: JSON.stringify({
          meetingId: meetingData.id,
          userId: userInfo.id,
          languageId: selectedLanguage
        })
      });
  
      console.log('Meeting durumu güncellendi:', {
        meetingData,
        isActive: isMeetingActiveRef.current,
        isInitialized: isTeamsMeetingInitialized.current
      });
  
      // Speech recognition başlat
      await initializeSpeechRecognition();
  
      const currentRecognizer = recognizers.current[selectedLanguage];
      if (!currentRecognizer) {
        throw new Error('Recognizer bulunamadı');
      }
  
      await new Promise((resolve, reject) => {
        currentRecognizer.startContinuousRecognitionAsync(
          () => {
            console.log('Dinleme başarıyla başlatıldı, durum:', {
              isActive: isMeetingActiveRef.current,
              meetingInfo: currentMeetingDataRef.current
            });
            setIsListening(true);
            resolve();
          },
          (error) => {
            console.error('Dinleme başlatma hatası:', error);
            reject(error);
          }
        );
      });
  
    } catch (err) {
      console.error('Dinleme başlatma hatası:', err);
      setError(err.message);
      await stopListening();
    }
  }, [
    isListening,
    isTeamsClient,
    speechServiceReady,
    userInfo,
    selectedLanguage,
    checkMicrophonePermission,
    stopListening,
    initializeSpeechRecognition
  ]);

  // Teams başlatma
  const initializeTeams = useCallback(async () => {
    if (isTeamsInitialized.current) {
      console.log('Teams zaten başlatılmış, atlanıyor...');
      return true;
    }
  
    try {
      console.log('Teams başlatılıyor...');
      await microsoftTeams.app.initialize();
      const context = await microsoftTeams.app.getContext();
      
      if (!context?.user) {
        throw new Error('Teams kullanıcı bilgileri bulunamadı');
      }
  
      console.log('Teams kullanıcı bilgileri alındı:', context.user);
      
      const userInfoData = {
        displayName: context.user.displayName || '',
        email: context.user.email,
        id: context.user.id,
        userPrincipalName: context.user.userPrincipalName
      };
  
      setUserInfo(userInfoData);
      setUserName(context.user.userPrincipalName || context.user.displayName);
      isTeamsInitialized.current = true;
  
      // userInfo'nun set edilmesini bekle
      await new Promise(resolve => setTimeout(resolve, 100));
  
      return true;
    } catch (err) {
      console.error('Teams başlatma hatası:', err);
      isTeamsInitialized.current = false;
      return false;
    }
  }, []);

// Meeting durumu değişikliklerini izle
useEffect(() => {
  console.log('Meeting durumu:', {
    isActive: isMeetingActive,
    meetingInfo,
    meetingRef: currentMeetingDataRef.current,
    isInitialized: isTeamsMeetingInitialized.current
  });
}, [isMeetingActive, meetingInfo]);

  useEffect(() => {
    console.log('Meeting durumu değişti:', {
      isActive: isMeetingActive,
      meetingInfo,
      meetingRef: currentMeetingDataRef.current
    });
  
    // Meeting aktif değilse ve dinleme devam ediyorsa durdur
    if (!isMeetingActive && isListening) {
      stopListening();
    }
  }, [isMeetingActive, isListening, stopListening, meetingInfo]);

  useEffect(() => {
    return () => {
      if (currentMeetingDataRef.current?.id) {
        stopListening();
      }
    };
  }, [stopListening]);
  
  // cleanupMeeting fonksiyonunu sadece component unmount olduğunda çağır
  useEffect(() => {
    return () => {
      if (meetingInfo?.id && isTeamsMeetingInitialized.current) {
        cleanupMeeting();
      }
    };
  }, [meetingInfo]);

  // Meeting temizleme
  const cleanupMeeting = useCallback(async () => {
    if (!meetingInfo?.id || !isTeamsMeetingInitialized.current) {
      return;
    }
  
    try {
      // Tarihi doğru formatta gönder
      const endDate = new Date().toISOString().slice(0, 19).replace('T', ' ');
      
      const response = await apiCall('/api/meetings', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          meetingId: meetingInfo.id,
          status: 'completed',
          endDate: endDate
        })
      });
  
      if (response.success) {
        isTeamsMeetingInitialized.current = false;
        setIsMeetingActive(false);
        console.log('Meeting başarıyla sonlandırıldı');
      } else {
        throw new Error(response.error || 'Meeting sonlandırılamadı');
      }
    } catch (error) {
      console.warn('Meeting sonlandırma hatası:', error);
      // Hata durumunda da state'i güncelle
      isTeamsMeetingInitialized.current = false;
      setIsMeetingActive(false);
    }
  }, [meetingInfo]);

  // Ana başlatma effect'i
  useEffect(() => {
    let mounted = true;
  
    const initializeApp = async () => {
      setIsInitializing(true);
      console.log('Uygulama başlatılıyor...');
  
      try {
        // Teams kontrolü
        const isTeams = await checkTeamsContext();
        if (!isTeams) {
          throw new Error('Bu uygulama sadece Teams Desktop Client üzerinde çalışır.');
        }
  
        // Speech servis kontrolü
        const isSpeechReady = await checkSpeechService();
        if (!isSpeechReady) {
          throw new Error('Speech servisi başlatılamadı.');
        }
  
        // Teams başlatma
        const teamsInitialized = await initializeTeams();
        if (!teamsInitialized) {
          throw new Error('Teams başlatılamadı.');
        }
  
        if (mounted) {
          // Teams context'ten meeting bilgilerini al
          const context = await microsoftTeams.app.getContext();
          if (context?.meeting?.id) {
            const meetingData = {
              id: context.meeting.id,
              name: context.meeting.name || 'Teams Meeting'
            };
  
            // Meeting'i aktifleştir
            const response = await apiCall('/api/meetings', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                meetingId: meetingData.id,
                meetingName: meetingData.name,
                hostUserId: userInfo?.id,
                hostUserName: userInfo?.displayName,
                status: 'active'
              })
            });
  
            if (response.success) {
              // Meeting bilgilerini güncelle
              currentMeetingDataRef.current = meetingData;
              isMeetingActiveRef.current = true;
              isTeamsMeetingInitialized.current = true;
              setMeetingInfo(meetingData);
              setIsMeetingActive(true);
  
              // Kullanıcı dil tercihini kaydet
              await apiCall('/api/user-preferences', {
                method: 'POST',
                body: JSON.stringify({
                  meetingId: meetingData.id,
                  userId: userInfo.id,
                  languageId: selectedLanguage
                })
              });
  
              console.log('Meeting otomatik olarak başlatıldı:', {
                meetingData,
                userInfo,
                selectedLanguage
              });
            }
          }
  
          // Mikrofon kontrolü
          const hasMicPermission = await checkMicrophonePermission();
          if (!hasMicPermission) {
            throw new Error('Mikrofon izni alınamadı.');
          }
  
          console.log('Uygulama başarıyla başlatıldı');
        }
      } catch (err) {
        console.error('Başlatma hatası:', err);
        if (mounted) {
          setError(err.message);
        }
      } finally {
        if (mounted) {
          setIsInitializing(false);
        }
      }
    };
  
    initializeApp();
  
    return () => {
      mounted = false;
      if (currentMeetingDataRef.current?.id) {
        stopListening();
      }
    };
  }, [
    checkTeamsContext,
    checkSpeechService,
    initializeTeams,
    checkMicrophonePermission,
    stopListening,
    userInfo,
    selectedLanguage
  ]);

  // Mesaj gösterme effect'i
  useEffect(() => {
    if (isMeetingActive && userInfo) {
      displayNextMessage();
      
      const interval = setInterval(fetchNewMessages, 5000);
      
      return () => {
        clearInterval(interval);
        if (displayTimeoutRef.current) {
          clearTimeout(displayTimeoutRef.current);
        }
      };
    }
  }, [isMeetingActive, userInfo, displayNextMessage, fetchNewMessages]);

  return (
    <div className="h-full w-full bg-white">
      <div className="flex flex-col h-full">
        {/* Header */}
        <div className="flex items-center justify-between px-3 py-2 bg-gray-50 border-b">
          {/* Sol Bölüm */}
          <div className="flex items-center space-x-4 flex-1">
            {/* Logo ve Başlık */}
            <div className="flex items-center space-x-2">
              <img src={colorLogo} alt="CSC Voice AI" className="h-8 w-8" />
              <h1 className="text-lg font-semibold text-gray-800">CSC Voice AI</h1>
            </div>

            {/* Kontrol Butonları */}
            <div className="flex space-x-2">
              <button 
                onClick={startListening}
                disabled={isListening || !micPermission || !isTeamsClient || !speechServiceReady}
                className={`px-3 py-1.5 rounded text-xs font-semibold transition-colors duration-200 ${
                  isListening || !micPermission || !isTeamsClient || !speechServiceReady
                    ? 'bg-gray-300 cursor-not-allowed text-gray-600' 
                    : 'bg-blue-500 hover:bg-blue-600 text-white'
                }`}
              >
                {isListening ? 'Dinleniyor...' : 'Dinlemeye Başla'}
              </button>
              
              <button 
                onClick={stopListening}
                disabled={!isListening}
                className={`px-3 py-1.5 rounded text-xs font-semibold transition-colors duration-200 ${
                  !isListening 
                    ? 'bg-gray-300 cursor-not-allowed text-gray-600' 
                    : 'bg-red-500 hover:bg-red-600 text-white'
                }`}
              >
                Dinlemeyi Durdur
              </button>
            </div>
          </div>

          {/* Sağ Bölüm */}
          <div className="flex items-center space-x-4">
            {/* Dil Seçimi */}
            <div className="flex items-center space-x-2">
              <span className="text-xs text-gray-600">Konuşma Dili:</span>
              <select
                value={selectedLanguage}
                onChange={handleLanguageChange}
                className="text-xs border rounded px-2 py-1.5 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:bg-gray-100"
                disabled={isListening}
              >
                {SUPPORTED_LANGUAGES.map(lang => (
                  <option key={lang.code} value={lang.code}>
                    {lang.icon} {lang.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Kullanıcı Bilgisi */}
            {userInfo && (
              <div className="flex items-center space-x-2">
                <span className="text-sm text-gray-600" title={userInfo.email || ''}>
                  {userInfo.displayName || userName}
                </span>
                {meetingInfo && (
                  <span className="text-xs text-green-600" title="Aktif">●</span>
                )}
              </div>
            )}
          </div>
        </div>

        {/* Teams Uyarısı */}
        {!isTeamsClient && (
          <div className="p-4 bg-yellow-50 border-b border-yellow-200">
            <p className="text-sm text-yellow-700">
              Bu uygulama sadece Microsoft Teams Desktop Client üzerinde çalışır.
            </p>
          </div>
        )}

        {/* Yükleniyor Göstergesi */}
        {isInitializing && (
          <div className="p-4 bg-blue-50">
            <p className="text-sm text-blue-700 flex items-center">
              <span className="w-2 h-2 bg-blue-600 rounded-full animate-pulse mr-2"></span>
              Uygulama başlatılıyor...
            </p>
          </div>
        )}

        {/* Hata Mesajları */}
        {error && (
          <div className="px-3 py-1 bg-red-50 border-b border-red-200">
            <p className="text-xs text-red-600">{error}</p>
            {!micPermission && (
              <button 
                onClick={checkMicrophonePermission}
                className="mt-1 px-2 py-0.5 bg-red-600 text-white text-xs rounded hover:bg-red-700"
              >
                Mikrofon İzni Ver
              </button>
            )}
          </div>
        )}

        {/* Ana İçerik - Diğer Kullanıcıların Mesajları */}
        <div className="flex-1 overflow-auto p-3 space-y-2">
          {displayedMessages.map((message) => (
            <div key={message.id} 
                 className="p-3 bg-white border rounded-lg shadow-sm transition-all duration-300 hover:shadow-md">
              {/* Mesaj Başlığı */}
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center space-x-2">
                  <span className="text-sm font-medium text-gray-800">
                    {message.userName}
                  </span>
                  <span className="text-xs text-gray-500">
                    {SUPPORTED_LANGUAGES.find(l => l.code === message.sourceLanguageId)?.icon}
                  </span>
                </div>
                <span className="text-xs text-gray-400">
                  {new Date(message.createdAt).toLocaleTimeString('tr-TR', {
                    hour: '2-digit',
                    minute: '2-digit'
                  })}
                </span>
              </div>

              {/* Mesaj İçeriği */}
              <div className="space-y-2">
                {/* Tercih Edilen Dildeki Metin */}
                <div className="bg-blue-50 p-2 rounded-md">
                  <div className="flex items-center space-x-2 mb-1">
                    <span className="text-xs font-medium text-blue-600">
                      {SUPPORTED_LANGUAGES.find(l => l.code === selectedLanguage)?.icon}
                      {SUPPORTED_LANGUAGES.find(l => l.code === selectedLanguage)?.name}
                    </span>
                    {message.confidenceScore && (
                      <span className="text-xs text-blue-400">
                        {(message.confidenceScore * 100).toFixed(0)}%
                      </span>
                    )}
                  </div>
                  <p className="text-sm text-gray-800">
                    {message.preferredTranslation || message.sourceText}
                  </p>
                </div>

                {/* Diğer Dillerdeki Çeviriler - Varsayılan Olarak Gizli */}
                <div className="mt-2">
                  <details className="text-xs">
                    <summary className="cursor-pointer text-gray-500 hover:text-gray-700">
                      Diğer dillerdeki çeviriler
                    </summary>
                    <div className="mt-2 space-y-2 pl-2">
                      {message.translations
                        .filter(t => t.languageId !== selectedLanguage)
                        .map((translation, index) => (
                          <div key={index} className="bg-gray-50 p-2 rounded">
                            <div className="flex items-center space-x-1 text-xs text-gray-600 mb-1">
                              {SUPPORTED_LANGUAGES.find(l => l.code === translation.languageId)?.icon}
                              <span>
                                {SUPPORTED_LANGUAGES.find(l => l.code === translation.languageId)?.name}
                              </span>
                            </div>
                            <p className="text-sm text-gray-700">
                              {translation.translatedText}
                            </p>
                          </div>
                        ))}
                    </div>
                  </details>
                </div>
              </div>
            </div>
          ))}

          {/* Mesaj Yok Göstergesi */}
          {displayedMessages.length === 0 && !isInitializing && (
            <div className="flex flex-col items-center justify-center h-full text-gray-500">
              <p className="text-sm">Henüz mesaj yok</p>
              <p className="text-xs mt-1">Konuşma başladığında mesajlar burada görünecek</p>
            </div>
          )}
        </div>

        {/* Debug Bilgisi */}
        <div className="p-2 bg-gray-50 border-t text-xs text-gray-500">
          <div>Teams Client: {isTeamsClient ? '✅' : '❌'}</div>
          <div>Speech Service: {speechServiceReady ? '✅' : '❌'}</div>
          <div>Mic yetkileri: {micPermission ? '✅' : '❌'}</div>
          <div>Meeting Active: {isMeetingActive ? '✅' : '❌'}</div>
        </div>

        {/* Dinleme Göstergesi */}
        {isListening && (
          <div className="fixed bottom-1 right-1 bg-blue-500 text-white px-2 py-0.5 rounded text-[10px] animate-pulse flex items-center gap-1 shadow-lg">
            <span className="w-1.5 h-1.5 bg-white rounded-full animate-pulse"></span>
            <span>Dinleniyor...</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default SpeechToTextComponent;